<template>
    <div class="inner_pages">
        <!-- <inner-sidebar /> -->
        <div class="respective_content" id="broadcast_index">
            <div class="result_wpr new">
                <div class="error-message">
                    <img :src="require('@/assets/images/sorry-page.svg')" />
                    <h2>Access Denied!</h2>
                    <h4 class="mt-3">
                        The owner of this content has determined you do not have the rights to use it and has therefore disabled it in your account.
                        <br />
                        <br />
                        <router-link :to="{ name: 'AppsIndex'}">
                            Click here
                        </router-link>
                        to return to your home page.
                    </h4>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Error 404',
    }
</script>

<style scoped>
    .error-message a {
        text-decoration: none;
    }

    .error-message h4 {
        font-weight: 500;
    }

    .error-message a,
    .error-message a:visited,
    .error-message a:hover,
    .error-message a:active {
        color: #2F7FED;
    }

    .error-message img {
        width: 40%;
    }
</style>